<template>
    <div class="interaction">
        <el-container>
            <el-main>
                <slideshow :slideshowList="slideshowList"></slideshow>
                <img class="debris" src="../../../static/scenery/debris.png" />
                <div class="content" style="width:60%;margin:0 auto">
                    <div class="bg-image2"></div>
                    <div class="bg-image3"></div>
                    <div class="content-title">
                        <img class="home-icon" src="../../../static/scenery/home.png" alt="" style="width:20;height:20;">
                        <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                        >
                        <span style="margin-right:6px" @click="Jump('/essential')">条子泥攻略</span>
                        >
                        <span style="margin-left:6px;color:#509AB1">景区互动</span>
                    </div>
                    <div class="message-board">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="姓名" prop="touristName">
                                <el-input v-model="ruleForm.touristName"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="telphone">
                                <el-input v-model="ruleForm.telphone"></el-input>
                            </el-form-item>
                            <el-form-item label="留言内容" prop="message">
                                <el-input type="textarea" v-model="ruleForm.message"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="submit" @click="submitForm('ruleForm')">提交留言</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <leftFloat :floatList="floatList" />
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { slideshow, tzn_interactionAPI } from '@/api/test/test'
export default {
    name:'FoodList',
    components:{},
    data(){
        // 自定义验证规则
        const checktelphone = (rule, value, callback) => {
            if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g.test(value)) {
                callback()
            } else { 
                callback(new Error('请输入正确的手机号码'))  
            }
        }
        const checktouristName = (rule, value, callback) => {
            if (
                /^[\u4e00-\u9fa5]{2,5}$/g.test(value)) {
                callback()
            } else { 
                callback(new Error('请输入2-5个字符'))  
            }
        }
        return{
            slideshowList:[],
            floatList: {
                list: [
                { name: "基本信息", url: "/essential" },
                { name: "舌尖美味", url: "/foodList" },
                { name: "酒店住宿", url: "/scenicSpot" },
                { name: "景区互动", url: "flush" },
                { name: "行程推荐", url: "/travel" },
                ],
                title: "条子泥攻略",
            },
            ruleForm: {
                touristName: '',
                telphone: '',
                message: ''
            },
            //规则
            rules: {
                touristName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {validator: checktouristName, trigger: 'blur'},
                    // { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                ],
                telphone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {validator: checktelphone, trigger: 'blur'},
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: '请填写留言内容', trigger: 'blur' }
                ]
            }
        }
    },
    created() {

    },
    mounted() {
        //头部轮播图
        slideshow().then(res => {
            this.slideshowList = res
            console.log(this.slideshowList);
        })
    },
    methods:{
        //提交
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                tzn_interactionAPI(this.ruleForm).then(res => {
                    console.log(res.entity,'------');
                    if (res.responseCode == 0) {
                        this.$message.success('留言成功')
                    } else {
                        this.$message.error('留言失败')
                    }
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        //左边列表跳换
        handleJump(url){
            this.$router.push(url)
        },
        //数据格式化
        decodeHTML(str) {
            var s = "";
            if (!str || str.length == 0) return "";
                s = str.replace(/&amp;/g, "&");
                s = s.replace(/&lt;/g, "<");
                s = s.replace(/&gt;/g, ">");
                s = s.replace(/&nbsp;/g, " ");
                s = s.replace(/&#39;/g, "\'");
                s = s.replace(/&quot;/g, "\"");
                return s;
        }
    }
}
</script>


<style scoped>

.interaction{
    /* height: 2450px; */
    background: url('../../../static/scenery/backgd.png') ;
}
.el-main {
    padding: 0;
    overflow-x: hidden;
}
/deep/ .el-carousel__indicators{
    bottom: 5%;
}
.debris{
    width: 100%;
    margin-top: -157px;
    z-index: 8;
    background: transparent;
    position: relative;
}
.content{
    position: relative;
}
.bg-image2{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/hly.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -33%;
    bottom: -12%;
}
.bg-image3{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/hlz.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -33%;
    bottom: 0;
}
.content-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 92px;
    background-image: url('../../../static/scenery/title.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    line-height: 92px;
    font-size: 24px;
    letter-spacing: 5px;
}
.content-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.home-icon{
    width: 20px;
    height: 20px;
    margin: 0 16px 0 53px;
}
.message-board{
    width: 100%;
    height: 1155px;
    background-image: url('../../../static/tzngl/interaction.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 120px auto 180px;
    box-sizing: border-box;
    padding: 300px 0 0 180px;
}
.el-input{
    width: 200px;
}
/deep/ .el-textarea__inner{
    width: 46%;
    height: 180px;
}
.submit{
    width: 160px;
    height: 52px;
    background-color: #fcf4e6;
    background-image: url('../../../static/tzngl/submit.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    border: none;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FCF1CC;
}

</style>